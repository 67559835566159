export default function downloadFile(res) {
	console.log(res.headers['content-disposition'])
	if (res.headers['content-disposition']) {
		if (window.navigator.msSaveOrOpenBlob) {
			// 兼容IE10
			navigator.msSaveBlob(new Blob([res.data]), decodeURI(res.headers['content-disposition'].split('=')[1]))

		} else {
			//  chrome/firefox
			const aTag = document.createElement('a')
			aTag.download = decodeURI(res.headers['content-disposition'].split('=')[1])
			aTag.href = URL.createObjectURL(new Blob([res.data], {type: 'application/vnd.ms-excel'}))
			aTag.click()
			URL.revokeObjectURL(aTag.href)

		}
	}

}