export const polySideOptions = {
  strokeColor: '#FF0000',
  strokeOpacity: 1,
  strokeWeight: 6,
  strokeOpacity: 0.8,
  fillColor: '#1791fc',
  fillOpacity: 0.4,
  strokeStyle: 'solid',
  draggable: true
  // 线样式还支持 'dashed'
  // strokeDasharray: [30,10],
}

export const aiderPolySideOptions = {
  strokeColor: '#0000FF',
  strokeOpacity: 1,
  strokeWeight: 6,
  strokeOpacity: 0.6,
  fillColor: '#1791fc',
  fillOpacity: 0.4,
  strokeStyle: 'solid',
  draggable: true
  // 线样式还支持 'dashed'
  // strokeDasharray: [30,10],
}

export function drawPolygon(mouseTool, withAider = false) {
  mouseTool.polygon(withAider ? aiderPolySideOptions : polySideOptions)
}

export function drawCircle(mouseTool) {
  mouseTool.circle(polySideOptions)
}

export function getPoints(obj) {
  const data = obj?.getPath?.()
  const points = []

  for (let i = 0; i < data.length; i++) {
    points.push([data[i].lng, data[i].lat])
  }

  return points
}
